
import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


//import OxfamMap from '../oxfamMap';
import Sarlahi from '../oxfam_data/Pages/Sarlahi';
import Rautahat from '../oxfam_data/Pages/Rautahat';
//import Dashboard from '../oxfam_data/Pages/Main-dashboard';


const Page = () => {
    return (<p>Page not found</p>)

}

const Routing = (props) => {

    return (
        <div>
            <Router>
                <Route exact path="/" render={() => <Sarlahi Data={props.Data} Covid_sarlahi_cases={props.Covid_sarlahi_cases} Covid_sarlahi_death={props.Covid_sarlahi_death} />} />
                <Route exact path="/sarlahi" render={() => <Sarlahi Data={props.Data} Covid_sarlahi_cases={props.Covid_sarlahi_cases} Covid_sarlahi_death={props.Covid_sarlahi_death} />} />
                <Route exact path="/rautahat" render={() => <Rautahat Data={props.Data} Covid_rautahat_cases={props.Covid_rautahat_cases} Covid_rautahat_death={props.Covid_rautahat_death} />} />

            </Router>

        </div>

    )

}

export default Routing;