import React, { useContext, useEffect, useState } from 'react';

import './App.css';

import Routing from './utils/Routing';
import Store from './oxfam_data/Store';
import Sarlahi from './oxfam_data/Pages/Sarlahi';
//import Chart from '../src/oxfam_data/Pages/Chart data/Chart';
import { DataContext } from './oxfam_data/Store';
import Axios from 'axios';
import Loader from 'react-loader';
import './oxfam_data/Pages/Oxfam.scss';
import { getNodeText } from '@testing-library/react';





function App() {
  const [Data, setData] = useState([]);
  const [Covid_sarlahi_cases, setCovid_sarlahi_cases] = useState();
  const [Covid_sarlahi_death, setCovid_sarlahi_death] = useState();
  const [Covid_rautahat_cases, setCovid_rautahat_cases] = useState();
  const [Covid_rautahat_death, setCovid_rautahat_death] = useState();
  useEffect(() => {

    Axios.get(process.env.REACT_APP_OXFAM_DATA, { headers: { "Authorization": process.env.REACT_APP_OXFAM_DATA_TOKEN } })
      .then(res => {
        setData(res.data)


      })
      .catch(err => {
        console.log(err);
      })

    Axios.get(process.env.REACT_APP_SARLAHI_DEATH)
      .then(res => {
        setCovid_sarlahi_death(res.data.count)
      })


      .catch(err => {
        console.log(err);
      })
    Axios.get(process.env.REACT_APP_RAUTAHAT_CASES)
      .then(res => {
        setCovid_rautahat_cases(res.data.count)
      })


      .catch(err => {
        console.log(err);
      })
    Axios.get(process.env.REACT_APP_RAUTAHAT_DEATH)
      .then(res => {
        setCovid_rautahat_death(res.data.count)
      })


      .catch(err => {
        console.log(err);
      })
    Axios.get(process.env.REACT_APP_SARLAHI_CASES)
      .then(res => {
        setCovid_sarlahi_cases(res.data.count)
      })


      .catch(err => {
        console.log(err);
      })
  }, [])

  if (Data.length === 0) {
    return (
      <div>
        <div className="loader"><Loader></Loader>
          <p>Loading Please Wait...</p>
        </div>

      </div>)
  }
  return (
    <div>
      <Store>
        <Routing Data={Data} Covid_sarlahi_cases={Covid_sarlahi_cases} Covid_sarlahi_death={Covid_sarlahi_death} Covid_rautahat_cases={Covid_rautahat_cases} Covid_rautahat_death={Covid_rautahat_death}>
        </Routing>
      </Store>





    </div>
  );
}

export default App;
