import React, { useContext, useState, useEffect } from 'react';
import ReactMapGl, { NavigationControl, Marker } from 'react-map-gl';
import { Bar } from 'react-chartjs-2';
import { WardContext, DistrictContext, MunicipalityContext, iswashContext, issanitarypadContext, Button_hilight1_Context, Button_hilight2_Context, Button_hilight3_Context, Button_hilight4_Context, Button_hilight5_Context, istoiletavailableContext, isfamilyinformationContext, iscovidinformationContext, ProvinceContext } from '../Store';
import '../../oxfam_data/Pages/Oxfam.scss';
import { Link } from 'react-router-dom';





const Sarlahi = (props) => {
    const [Ward] = useContext(WardContext);

    const [Province] = useContext(ProvinceContext);
    const [Iswash, setIswash] = useContext(iswashContext);
    const [Issanitarypad, setIssanitarypad] = useContext(issanitarypadContext);
    const [Istoiletavailable, setIstoiletavailable] = useContext(istoiletavailableContext);
    const [Isfamilyinformation, setIsfamilyinformation] = useContext(isfamilyinformationContext);
    const [Iscovidinformation, setIscovidinformation] = useContext(iscovidinformationContext);
    const [District, setDistrict] = useContext(DistrictContext);
    const [Municipality, setMunicipality] = useContext(MunicipalityContext);
    const [Legend_wash_available, setLegend_wash_available] = useState([]);
    const [Legend_sanitary_available, setLegend_sanitary_available] = useState([]);
    const [Legend_toilet_available, setLegend_toilet_available] = useState([]);
    const [Legend_family_details, setLegend_family_details] = useState([]);
    const [isLegend_family_details, setIsLegend_family_details] = useState(false);
    const [isLegend_toilet_available, setIsLegend_toilet_available] = useState(false);
    const [isLegend_wash_available, setIsLegend_wash_available] = useState(false);
    const [isLegend_covid_available, setIsLegend_covid_available] = useState(false);
    const [Legend_covid_details, setLegend_covid_details] = useState([]);
    const [isLegend_sanitary_available, setIsLegend_sanitary_available] = useState(false);
    const [Button_hilight1, setButton_hilight1] = useContext(Button_hilight1_Context);
    const [Button_hilight2, setButton_hilight2] = useContext(Button_hilight2_Context);
    const [Button_hilight3, setButton_hilight3] = useContext(Button_hilight3_Context);
    const [Button_hilight4, setButton_hilight4] = useContext(Button_hilight4_Context);
    const [Button_hilight5, setButton_hilight5] = useContext(Button_hilight5_Context);
    const [NewUpdate, setNewUpdate] = useState([]);
    const [Child_below_5_sarlahi, setChild_below_5_sarlahi] = useState(null);
    const [Disabled_sarlahi, setDisabled_sarlahi] = useState(null);
    const [Adult_above_80_sarlahi, setAdult_above_80_sarlahi] = useState(null);
    const [Chronic_illness_sarlahi, setChronic_illness_sarlahi] = useState(null);
    const [Newcovid_Family_data, setNewcovid_Family_data] = useState([]);
    const [NewDemo, setNewDemo] = useState([]);
    const [Pregnant_women, setPregnant_women] = useState(null);
    const [Breast_feeding, setBreast_feeding] = useState(null);



    const [viewport, setviewPort] = useState({

        latitude: 26.986159229293165,
        longitude: 85.56531220869543,
        width: window.screen.width > 500 ? "70vw" : "100vw",
        height: "100vh",
        zoom: 10.1

    })
    const [Changed, setChanged] = useState(false)

    let Data = props.Data || [];
    let Update = NewUpdate;
    let Covid_sarlahi_death = props.Covid_sarlahi_death;
    let Covid_sarlahi_cases = props.Covid_sarlahi_cases;
    let Demo = NewDemo;//for covid cases analysis
    let total_family_covid_data = Newcovid_Family_data;//total family members in survey


    const handleEffect = (e) => {

        for (let i = 0; i < Data.length; i++) {
            let data = Data[i]

            data.district_id = District.filter((item) => item.title_ne === data.district).map((item) => item.centroid.coordinates);
            data.municipality_id = Municipality.filter((item) => item.title_ne === data.palika).map((item) => item.centroid.coordinates);
            data.municipality_i = Municipality.filter((item) => item.title_ne === data.palika).map((item) => item.id);
            data.Total_child_below_5 = String(data.total_male_below5 + data.total_female_below5);
            data.chronic_illness = String(data.total_heart_problem + data.total_hiv + data.total_kidney_problem + data.total_liver_problem + data.total_lungs_problem + data.total_cancer + data.total_diabetes + data.total_fat_problem);
            data.Total_adult_above_80 = String(data.total_female_above80 + data.total_male_above80);
            data.pregnant_cases = String(data.total_pregnant)
            data.breastfeeding_cases = String(data.total_feeding);
            data.Total_disabled = String(data.total_male_disabled + data.total_female_disabled);
            if (data.ward === 0) {
                data.ward = 1
            }
            data.wards = Ward.filter((item) => item.municipality === data.municipality_i[0]).filter((item) => Number(item.title) === data.ward).map((item) => item.centroid.coordinates);


            data.fake_coordinates = [data.wards[0][0] + Math.random() * 0.016, data.wards[0][1] + Math.random() * 0.016]
            Update.push(data);

        }
        let Total_child_below_5_sarlahi = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setChild_below_5_sarlahi(prev + Number(current.Total_child_below_5))
            return prev + Number(current.Total_child_below_5)
        }, 0);
        let Total_chronic_illness_sarlahi = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setChronic_illness_sarlahi(prev + Number(current.chronic_illness))
            return prev + Number(current.chronic_illness)
        }, 0);
        let Total_adult_above_80_sarlahi = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setAdult_above_80_sarlahi(prev + Number(current.Total_adult_above_80))
            return prev + Number(current.Total_adult_above_80)
        }, 0);
        let Total_disabled_sarlahi = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setDisabled_sarlahi(prev + Number(current.Total_disabled))
            return prev + Number(current.Total_disabled)
        }, 0);
        let Total_Pregnant_women = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setPregnant_women(prev + Number(current.total_pregnant))
            return prev + Number(current.total_pregnant)
        }, 0);
        let Total_Breast_feeding = Update.filter((item) => item.district === "सर्लाही").reduce(function (prev, current) {
            setBreast_feeding(prev + Number(current.total_feeding))
            return prev + Number(current.total_feeding)
        }, 0);


        let Total_drycough = 0;
        let total_data = [];
        for (let i = 0; i < Data.length; i++) {
            let data = Data[i]
            data.wardcoordinate = Ward.filter((item) => Number(item.title) === data.ward).map((item) => item.centroid.coordinates);
            data.provincecoordinate = Province.filter((item) => item.title_ne === data.province).map((item) => item.centroid.coordinates);
            data.districtcoordinate = District.filter((item) => item.title_ne === data.district).map((item) => item.centroid.coordinates);
            data.municipalitycoordinate = Municipality.filter((item) => item.title_ne === data.palika).map((item) => item.centroid.coordinates);
            Demo.push(data)
        }


        for (let i = 0; i < Data.length; i++) {
            let data = Data[i];

            for (let i = 0; i < data.family.length; i++) {
                let fam_data = data.family[i];
                fam_data.province = Demo.filter((item) => item.id === fam_data.form).map((item) => item.province);
                fam_data.district = Demo.filter((item) => item.id === fam_data.form).map((item) => item.district);
                fam_data.municipality = Demo.filter((item) => item.id === fam_data.form).map((item) => item.palika);
                fam_data.wards_coordinate = Demo.filter((item) => item.id === fam_data.form).map((item) => item.wards[0]);
                fam_data.ward_no = Demo.filter((item) => item.id === fam_data.form).map((item) => item.ward)
                fam_data.fake_coordinates = Update.filter((item) => item.id === fam_data.form).map((item) => item.fake_coordinates)
                total_family_covid_data.push(fam_data);
            }
        }

    }

    const Wash = {
        labels: ['WASH Facility ',
            'Boiled Water Available ',
            'Soap Available',
            'Personal Tubewell',
            'Community Tubewell',
            'Systematic Drinking Water'
        ],
        datasets: [
            {

                label: [''

                ],
                backgroundColor: [
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'

                ],
                borderColor: [
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'

                ],

                borderWidth: 2,
                barPercentage: 0.5,
                barThickness: 15,
                maxBarThickness: 50,
                minBarLength: 10,
                data: [Update.filter((item) => item.district === "सर्लाही").filter((item) => item.wash_facility === "छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_boil === "छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.soap_available === "छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === "व्यक्तिगत चापा कल (टयुबवेल)").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === "सामुदायीक चापाकल (टयुबवेल)").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === "प्रणालीगत खानेपानी").length,


                ]
            }
        ]
    }

    const Sanitary = {
        labels: [' Teenage Girls ',
            'Menstrual Supplies',
            'Sanitary Pad Available',
            'Timely Pad Change',
            'Pad Disposal',
            'Expert Consult'
        ],
        datasets: [
            {

                label: [''

                ],
                backgroundColor: [
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'
                ],
                borderColor: [
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'
                ],
                borderWidth: 2,
                barPercentage: 0.5,
                barThickness: 15,
                maxBarThickness: 50,
                minBarLength: 10,
                data: [
                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.girls_available === "छ").length,
                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.mensuration_management === "स्यानिटरी प्याड र सुती कपडा दुवै" || "स्यानिटरी प्याड" || "सुती कपडा").length,
                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.sanitary_pad_available === "छ").length,

                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.pad_change_timely === "छ").length,

                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.pad_disposal === "छ").length,
                    Update.filter((item) => item.district === "सर्लाही").filter((item) => item.mensuration_consult === "छ").length,


                ]
            }
        ]
    }
    const Toilet = {
        labels: ['Toilet Available ',
            'Toilet Unavailable',
            'Maintainance Required',
            'Panless Toilet',
            'Toilet Cleaning Kit'
        ],
        datasets: [
            {

                label: [''

                ],
                backgroundColor: [
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'

                ],
                borderColor: [
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'

                ],
                borderWidth: 2,
                barPercentage: 0.5,
                barThickness: 15,
                maxBarThickness: 50,
                minBarLength: 10,
                data: [Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === "छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === "छैन").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet_no_use !== "प्यान फुटेको छ").length,

                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === "छ").filter((item) => item.toilet_no_use === "प्यान फुटेको छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet_clean_utils === "छ").length,

                ]
            }
        ]
    }
    const Family_info = {
        labels: ['Disabled',
            'Infant Below 5',
            'Chronic Illness',
            'Breast Feeding',
            'Pregnant Women'
        ],
        datasets: [
            {

                label: [''

                ],
                backgroundColor: [
                    '#F7D002',
                    '#3A9964',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'
                ],
                borderColor: [
                    '#F7D002',
                    '#3A9964',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'
                ],
                borderWidth: 2,
                barPercentage: 0.5,
                barThickness: 15,
                maxBarThickness: 50,
                minBarLength: 10,
                data: [Disabled_sarlahi,
                    Child_below_5_sarlahi,
                    Chronic_illness_sarlahi,
                    Breast_feeding,
                    Pregnant_women
                ]
            }
        ]
    }

    const COVID_info = {
        labels: ['Breathing Problem ',
            'Dry Cough',
            'Tiredness',
            'Normal Temperature',
            'Fever',
            'Returnee',
            'Quarentined'

        ],
        datasets: [
            {

                label: [''

                ],
                backgroundColor: [
                    '#3c368e',
                    '#07a841',
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'



                ],
                borderColor: [
                    '#3c368e',
                    '#07a841',
                    '#F7D002',
                    '#3A9964',
                    '#D67A0A',
                    '#BC1D3D',
                    '#3DADF8',
                    '#f05620'



                ],
                borderWidth: 2,
                barPercentage: 0.5,
                barThickness: 15,
                maxBarThickness: 50,
                minBarLength: 10,
                data: [total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.breath_problem === "छ").length,
                total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.dry_cough === "छ").length,
                total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.tiredness === "छ").length,
                total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.temperature === "सामान्य (९६-९८.६ )").length,
                total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.temperature === "ज्वरो (९८.६ -१०२ )").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.arrival === "छ").length,
                Update.filter((item) => item.district === "सर्लाही").filter((item) => item.arrival === "छ").filter((item) => item.arrival_quarantine === "थियो").length

                ]
            }
        ]

    }
    const handleWashfacilityavailable = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.wash_facility === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })


    }

    const handleWarmdrinkinginfo = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.warm_drink_info === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                district: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }
    const handleBoilwateravailable = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_boil === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })




    }

    const handlePersonaltubewell = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })




    }
    const handleCommunitytubewell = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }

    const handleSystematicdrinkingwater = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.water_resource === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }

    const handleTeenagegirl = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.girls_available === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }

    const handleSanitarypadavailable = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.sanitary_pad_available === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleSanitarypaduse = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.mensuration_management === "स्यानिटरी प्याड र सुती कपडा दुवै" || "स्यानिटरी प्याड र सुती कपडा दुवै" || "सुती कपडा").map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }
    const handleTimelypadchange = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.pad_change_timely === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }


    const handlePaddisposal = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.pad_disposal === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }




    const handleSoapavailable = (e) => {
        setIsLegend_wash_available(true)
        setLegend_covid_details(false);
        setLegend_wash_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.soap_available === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })


    }
    const handleMensurationconsult = (e) => {
        setIsLegend_sanitary_available(true)
        setLegend_covid_details(false);
        setLegend_sanitary_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.mensuration_consult === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }

    const handleToiletavailable = (e) => {
        setIsLegend_toilet_available(true)
        setLegend_covid_details(false);
        setLegend_toilet_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })




    }
    const handleToiletunavailable = (e) => {
        setIsLegend_toilet_available(true)
        setLegend_covid_details(false);
        setLegend_toilet_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })



    }

    const handleToiletpanbroken = (e) => {
        setIsLegend_toilet_available(true)
        setLegend_covid_details(false);
        setLegend_toilet_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === "छ").filter((item) => item.toilet_no_use === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }

    const handleToiletmaintainancerequired = (e) => {
        setIsLegend_toilet_available(true)
        setLegend_covid_details(false);
        setLegend_toilet_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet_no_use !== "प्यान फुटेको छ").map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }

    const handleCleanutils = (e) => {
        setIsLegend_toilet_available(true)
        setLegend_covid_details(false);
        setLegend_toilet_available(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet_clean_utils === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }


    const handleNormaltemperature = (e) => {
        setIsLegend_covid_available(true)
        setIsLegend_wash_available(false)
        setLegend_covid_details(total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.temperature === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district[0],
                municipality: item.municipality[0],
                ward: item.ward_no[0]

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }

    const handleFever = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.temperature === "ज्वरो (९८.६ -१०२ )").map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district[0],
                municipality: item.municipality[0],
                ward: item.ward_no[0]

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleTotaldisabled = (e) => {
        setIsLegend_family_details(true)
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.Total_disabled === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleTotalinfantlessthan5 = (e) => {
        setIsLegend_family_details(true);
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.Total_child_below_5 === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleTotaladultgreaterthan80 = (e) => {
        setIsLegend_family_details(true);
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.Total_adult_above_80 === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }

    const handleChronicillness = (e) => {
        setIsLegend_family_details(true)
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.chronic_illness === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleBreastfeeding = (e) => {
        setIsLegend_family_details(true)
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.breastfeeding_cases === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })


    }
    const handlePregnant = (e) => {
        setIsLegend_family_details(true)
        setLegend_family_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.pregnant_cases === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }



    const handleBreatheproblem = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.breath_problem === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district[0],
                municipality: item.municipality[0],
                ward: item.ward_no[0]

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleTiredness = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.tiredness === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district[0],
                municipality: item.municipality[0],
                ward: item.ward_no[0]

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })

    }
    const handleDrycough = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.dry_cough === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district[0],
                municipality: item.municipality[0],
                ward: item.ward_no[0]

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })


    }
    const handleQuarentined = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.arrival === "छ").filter((item) => item.arrival_quarantine === e.target.value).map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                itemId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward

            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }
    const handleReturnee = (e) => {
        setIsLegend_covid_available(true);
        setIsLegend_wash_available(false)
        setLegend_covid_details(Update.filter((item) => item.district === "सर्लाही").filter((item) => item.arrival === "छ").map(item => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: item.id,
                category: item.district,
                municipality: item.palika,
                ward: item.ward
            },
            geometry: { type: "Point", coordinates: item.fake_coordinates }

        })))
        //This is sort of CSS-only; the JS below just sticks a span around each letter, so i can animate each independantly.
        //(oh for an :nth-letter selector!)
        const labels = document.querySelectorAll('.label');
        labels.forEach(label => {
            const chars = label.textContent.split('');
            label.innerHTML = '';
            chars.forEach(char => {
                label.innerHTML += `<span>${char === ' ' ? '&nbsp' : char}</span>`;
            });
        })
    }



    let Sarlahi_wash_details = Update.filter((item) => item.district === "सर्लाही").filter((item) => item.wash_facility === "छ").map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            crimeId: item.id,
            category: item.district,
            municipality: item.palika,
            ward: item.ward
        },
        geometry: { type: "Point", coordinates: item.fake_coordinates }

    }))
    let Sarlahi_COVID_Details = total_family_covid_data.filter((item) => item.district[0] === "सर्लाही").filter((item) => item.breath_problem === "छ").map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            itemId: item.id,
            category: item.district[0],
            municipality: item.municipality[0],
            ward: item.ward_no[0]

        },
        geometry: { type: "Point", coordinates: item.fake_coordinates[0] }

    }))
    let Sarlahi_Sanitary_Details = Update.filter((item) => item.district === "सर्लाही").filter((item) => item.girls_available === "छ").map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            crimeId: item.id,
            category: item.district,
            municipality: item.palika,
            ward: item.ward
        },
        geometry: { type: "Point", coordinates: item.fake_coordinates }

    }))


    let Sarlahi_Toilet_Details = Update.filter((item) => item.district === "सर्लाही").filter((item) => item.toilet === "छ").map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            crimeId: item.id,
            category: item.district,
            municipality: item.palika,
            ward: item.ward
        },
        geometry: { type: "Point", coordinates: item.fake_coordinates }

    }))

    let Sarlahi_family_Details = Update.filter((item) => item.district === "सर्लाही").filter((item) => item.Total_disabled !== "0").map(item => ({
        type: "Feature",
        properties: {
            cluster: false,
            crimeId: item.id,
            category: item.district,
            municipality: item.palika,
            ward: item.ward
        },
        geometry: { type: "Point", coordinates: item.fake_coordinates }

    }))

    // let Covid_sarlahi_recovered = Covid_data_sarlahi.filter((item) => item.currentState === "recovered").map(item => ({
    //     type: "feature",
    //     properties: {
    //         cluster: false,
    //         patientId: item.id,
    //         category: item.district,
    //         municipality: item.municipality

    //     },
    //     geometry: { type: "Point", coordinates: item.point.coordinates }

    // }))

    // let Covid_sarlahi_active = Covid_data_sarlahi.filter((item) => item.currentState === "active").map(item => ({
    //     type: "feature",
    //     properties: {
    //         cluster: false,
    //         patientId: item.id,
    //         category: item.district,
    //         municipality: item.municipality

    //     },
    //     geometry: { type: "Point", coordinates: item.point.coordinates }

    // }))

    // let Covid_sarlahi_death = Covid_data_sarlahi.filter((item) => item.currentState === "death").map(item => ({
    //     type: "feature",
    //     properties: {
    //         cluster: false,
    //         patientId: item.id,
    //         category: item.district,
    //         municipality: item.municipality

    //     },
    //     geometry: { type: "Point", coordinates: item.point.coordinates }

    // }))


    useEffect(() => {

        handleEffect();
        setNewUpdate(Update)
        setNewDemo(Demo);
    }, [])



    return (

        <div>

            <div>
                <div className="sarlahi-parent">
                    <div className=" test">
                        {Iswash ? <div className="details">
                            <h2>WASH FACILITY  </h2>
                            <h2 className="heading1"><i>33.34% of households in Sarlahi have systematic drinking water facility.</i>  </h2>
                            <p>Sarlahi district borders Mahottari district in the east, Bagmati river in the west, Sivalik Hills in the north, and India in the south. Every monsoon, Sarlahi is hit hard by floods. According to the 2011 census, out of 132,803 households, 102,551 (77.22%) households have tube well / handpump as a source of drinking water, however, the quality of drinking water remains a big question.  </p>
                            <p>Out of 186 surveyed families, 141 (75.80%) families have WASH facilities. Similarly, 140 (75.27%) families have access to boiled drinking water. Regarding the source of drinking water, 122 (65.59%) households have personal tube wells, and only 62 (33.33%) households have a systematic drinking water facility.</p>
                            <div className="bar"></div>

                            <Bar
                                data={Wash}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'WASH Details of Sarlahi',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: false,
                                        position: 'right'
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                fontColor: 'white'
                                            },
                                        }],
                                        xAxes: [{
                                            ticks: {
                                                fontColor: 'white'
                                            },
                                        }]
                                    }
                                }}
                                width={800} height={600}
                            />
                        </div> : ''
                        }
                        {Istoiletavailable ? <div className="details">
                            <h2>TOILET FACILITY </h2>
                            <h2 className="heading1"><i>97.31% of households in Sarlahi have toilet facilities in their house.</i></h2>
                            <p>According to the 2011 census, in Sarlahi out of 132,803 households, 97,720 (73.58%) households did not have access to the toilet. Only 1,587 (1.20%) households had a toilet with a flush facility and access to public sewerage. The limited access to the toilet could lead to major health and hygiene complications to the families and communities.</p>
                            <p>In our survey, out of 186 families, 181 (97.31%) households had a toilet in their house, and 172 (92.47%) households responded that they use toilet cleaning kit to keep their toilet clean. Two household had broken toilet door and 179 (96.23%) households had panless toilet.</p>
                            <div className="bar"></div>
                            <Bar
                                data={Toilet}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Toilet Details of Sarlahi',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: false,
                                        position: 'right'
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                fontColor: 'white'
                                            },
                                        }],
                                        xAxes: [{
                                            ticks: {
                                                fontColor: 'white'
                                            },
                                        }]
                                    }
                                }}
                                width={800} height={600}
                            />
                        </div> : ''
                        }
                        {Issanitarypad ?
                            <div className="details">
                                <h2>MENSTRUAL HYGIENE </h2>
                                <h2 className="heading1"><i>75.26% of family have access to sanitary pad available in their house.  </i>  </h2>
                                <p>We also surveyed information regarding menstrual hygiene. Out of 186 families surveyed in Sarlahi district, 660 people were female of different age-group and 137 families had teenage girls at home.</p>
                                <p>Among the surveyed families, 119 families (63.98%) responded that they use sanitary pad during menstruation, 19 (10.22%) use cotton cloths, and 48 (25.80%) use both cotton cloths and sanitary pad. Regarding the access to sanitary pad, 141 families (75.81%) said they have access to sanitary pad available in their house. Likewise, 182 (97.85%) families said female(s) in the family change their pad timely, 180 (96.77%) said they dispose pad properly, and 173 (93.10%) said they have consulted experts regarding menstrual hygiene.</p>
                                <div className="bar"></div>
                                <Bar
                                    data={Sanitary}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Menstrual Practices of Sarlahi',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    fontColor: 'white'
                                                },
                                            }],
                                            xAxes: [{
                                                ticks: {
                                                    fontColor: 'white'
                                                },
                                            }]
                                        }
                                    }}
                                    width={800} height={600}
                                />

                            </div> : ''

                        }
                        {Isfamilyinformation ?
                            <div className="details">
                                <h2>HOUSEHOLD INFORMATION </h2>
                                <h2 className="heading1"><i>36 people with chronic diseases who need to be treated with precautions.  </i>  </h2>
                                <p>As per the 2011 census, Sarlahi district consists of 0.86% of Nepal’s total area and 2.91% of the country’s total population. In figure, the total population of the district is 769,729 in which family number is 132,844 and the average family size is 5.79.  </p>
                                <p>In our survey of 186 households, we found a total of 247 infants below the age of five and none above 80 years. We found 19 people living with disabilities, 17 pregnant women, 57 breastfeeding women, and 36 people with chronic diseases who need to be treated with precautions. The number of these vulnerable group are highlighted as they are at high risk of the ongoing COVID19 pandemic.    </p>
                                <div className="bar"></div>
                                <Bar
                                    data={Family_info}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Details of Vulnerable Groups of Sarlahi',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    fontColor: 'white'
                                                },
                                            }],
                                            xAxes: [{
                                                ticks: {
                                                    fontColor: 'white',

                                                },
                                            }]
                                        }
                                    }}
                                    width={800} height={600}
                                />
                            </div> : ''

                        }
                        {Iscovidinformation ?
                            <div className="details">
                                <h2>COVID19 INFO </h2>
                                <h2 className="heading1"><i>45 people stayed in quarantine who had returned from abroad.</i>  </h2>
                                <p>Amidst the pandemic outbreak of COVID-19, people around the world are being widely affected. The increasing rate of COVID-19 infection has not only created a state of global emergency but also created extreme levels of fear, desperation, and hopelessness among the people. In this global pandemic, Nepal has not remained untouched. The southern plain of the country has been largely affected given a higher number of COVID-19 positive cases.</p>
                                <p>In a survey of 154 people that was conducted in Sarlahi, 45 people responded that they had stayed in quarantine who had returned from abroad, primarily from India. Among the surveyed individuals 153 had normal temperature, one had fever, 15 had dry cough, 7 had breathing problem, and 20 people were feeling tired.</p>
                                <div className="bar"></div>
                                <Bar
                                    data={COVID_info}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'COVID-19 Details of Sarlahi',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    fontColor: 'white'
                                                },
                                            }],
                                            xAxes: [{
                                                ticks: {
                                                    fontColor: 'white'
                                                },
                                            }]
                                        }
                                    }}
                                    width={800} height={600}
                                />

                            </div>

                            : ''

                        }

                        <div className="why">

                            <div className="btn-toolbar You" role="toolbar" aria-label="Toolbar with button groups">
                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                    <button type="button" className={Button_hilight1 ? "btn btn-secondary active " : "btn btn-secondary"} value="1" onClick={(e) => {
                                        e.preventDefault()
                                        setIssanitarypad(false)
                                        setIstoiletavailable(false)
                                        setIswash(false)
                                        setIsfamilyinformation(false)
                                        setIscovidinformation(true)
                                        setIsLegend_covid_available(false)
                                        setLegend_wash_available([])
                                        setLegend_sanitary_available([]);
                                        setLegend_toilet_available([])
                                        setLegend_family_details([])
                                        setButton_hilight1(true);
                                        setButton_hilight2(false);
                                        setButton_hilight3(false);
                                        setButton_hilight4(false);
                                        setButton_hilight5(false);
                                    }}>1</button>
                                    <button type="button" className={Button_hilight2 ? "btn btn-secondary active" : "btn btn-secondary"} value="2" onClick={
                                        (e) => {
                                            e.preventDefault()
                                            setIssanitarypad(false)
                                            setIstoiletavailable(false)
                                            setIswash(false)
                                            setIsfamilyinformation(true)
                                            setIscovidinformation(false)
                                            setIsLegend_family_details(false)
                                            setLegend_covid_details([])
                                            setLegend_wash_available([])
                                            setLegend_sanitary_available([]);
                                            setLegend_toilet_available([]);
                                            setButton_hilight1(false);
                                            setButton_hilight2(true);
                                            setButton_hilight3(false);
                                            setButton_hilight4(false);
                                            setButton_hilight5(false);
                                        }
                                    }>2</button>
                                    <button type="button" className={Button_hilight3 ? "btn btn-secondary active" : "btn btn-secondary"} value="3" onClick={(e) => {
                                        e.preventDefault()

                                        setIswash(false)
                                        setIsfamilyinformation(false)
                                        setIstoiletavailable(false)
                                        setIscovidinformation(false)
                                        setIssanitarypad(true)
                                        setIsLegend_sanitary_available(false)
                                        setLegend_toilet_available([])
                                        setLegend_family_details([])
                                        setLegend_covid_details([])
                                        setLegend_wash_available([])
                                        setButton_hilight1(false);
                                        setButton_hilight2(false);
                                        setButton_hilight3(true);
                                        setButton_hilight4(false);
                                        setButton_hilight5(false);

                                    }}>3</button>
                                    <button type="button" className={Button_hilight4 ? "btn btn-secondary active" : "btn btn-secondary"} value="4" onClick={(e) => {
                                        e.preventDefault()
                                        setIstoiletavailable(true)
                                        setIswash(false)
                                        setIssanitarypad(false)
                                        setIscovidinformation(false)
                                        setIsfamilyinformation(false)
                                        setIsLegend_toilet_available(false)
                                        setLegend_family_details([])
                                        setLegend_covid_details([])
                                        setLegend_wash_available([])
                                        setLegend_sanitary_available([]);
                                        setButton_hilight1(false);
                                        setButton_hilight2(false);
                                        setButton_hilight3(false);
                                        setButton_hilight4(true);
                                        setButton_hilight5(false);
                                    }}>4</button>
                                    <button type="button" className={Button_hilight5 ? "btn btn-secondary active" : "btn btn-secondary"} value="5" onClick={(e) => {
                                        e.preventDefault()
                                        setIswash(true)
                                        setIssanitarypad(false)
                                        setIscovidinformation(false)
                                        setIstoiletavailable(false)
                                        setIsfamilyinformation(false)
                                        setIsLegend_wash_available(false)
                                        setLegend_sanitary_available([]);
                                        setLegend_toilet_available([])
                                        setLegend_covid_details([])
                                        setLegend_family_details([])
                                        setButton_hilight1(false);
                                        setButton_hilight2(false);
                                        setButton_hilight3(false);
                                        setButton_hilight4(false);
                                        setButton_hilight5(true);
                                    }}>5</button>
                                </div>

                            </div>
                            <div className="dist-choose">
                                <button className="but"><Link to="/sarlahi" className="district-link"><b>SARLAHI</b></Link></button> <button className="but"> <Link to="/rautahat" className="district-link" >RAUTAHAT</Link></button>

                            </div>

                        </div>

                    </div>
                    <div className="map-border">
                        <ReactMapGl className="map-react" {...viewport} mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN} mapStyle='mapbox://styles/nabanit/ckd2oohm500pw1iqh3cixq9ly'
                            onViewportChange={(viewport) => setviewPort(viewport)} maxZoom={20} minZoom={10}  >

                            <NavigationControl className="nav-control" />
                            {Iswash ?
                                <div className="legend">
                                    <label htmlFor="ward wise data" className="lvl">SARLAHI WASH FACILITY</label><br />
                                    <div className="bulgy-radios" role="radiogroup" aria-labelledby="bulgy-radios-label">

                                        <label>
                                            <input type="radio" name="options" value="छ" defaultChecked onClick={handleWashfacilityavailable} />
                                            <span className="radio" />
                                            <span className="label">WASH Facility</span>
                                        </label>

                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleBoilwateravailable} />
                                            <span className="radio" />
                                            <span className="label">Boiled Water Available</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleSoapavailable} />
                                            <span className="radio" />
                                            <span className="label">Soap Use</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="व्यक्तिगत चापा कल (टयुबवेल)" onClick={handlePersonaltubewell} />
                                            <span className="radio" />
                                            <span className="label">Personal Tubewell</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="सामुदायीक चापाकल (टयुबवेल)" onClick={handleCommunitytubewell} />
                                            <span className="radio" />
                                            <span className="label">Community Tubewell</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="प्रणालीगत खानेपानी" onClick={handleSystematicdrinkingwater} />
                                            <span className="radio" />
                                            <span className="label">Systematic Drinking Water</span>
                                        </label>

                                    </div>
                                </div>

                                : ''
                            }
                            {Istoiletavailable ?
                                <div className="legend">
                                    <label htmlFor="ward wise data" className="lvl">SARLAHI TOILET FACILITY </label><br />
                                    <div className="bulgy-radios" role="radiogroup" aria-labelledby="bulgy-radios-label">

                                        <label>
                                            <input type="radio" name="options" value="छ" defaultChecked onClick={handleToiletavailable} />
                                            <span className="radio" />
                                            <span className="label">Toilet Available</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छैन" onClick={handleToiletunavailable} />
                                            <span className="radio" />
                                            <span className="label">Toilet Unavailable</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="अन्य" onClick={handleToiletmaintainancerequired} />
                                            <span className="radio" />
                                            <span className="label">Maintainance Required</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="प्यान फुटेको छ" onClick={handleToiletpanbroken} />
                                            <span className="radio" />
                                            <span className="label">Panless Toilet</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleCleanutils} />
                                            <span className="radio" />
                                            <span className="label">Toilet Cleaning Kit</span>
                                        </label>
                                    </div>
                                </div>
                                : ''
                            }
                            {Issanitarypad ?
                                <div className="legend">

                                    <label htmlFor="ward wise data" className="lvl">SARLAHI MENSTRUAL HYGIENE </label><br />
                                    <div className="bulgy-radios" role="radiogroup" aria-labelledby="bulgy-radios-label">
                                        <label>
                                            <input type="radio" name="options" value="छ" defaultChecked onClick={handleTeenagegirl} />
                                            <span className="radio" />
                                            <span className="label">Teenage Girls</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="स्यानिटरी प्याड" onClick={handleSanitarypaduse} />
                                            <span className="radio" />
                                            <span className="label">Menstrual supplies</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleSanitarypadavailable} />
                                            <span className="radio" />
                                            <span className="label">Sanitary Pad Available</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleTimelypadchange} />
                                            <span className="radio" />
                                            <span className="label">Timely Pad Change</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handlePaddisposal} />
                                            <span className="radio" />
                                            <span className="label">Pad Disposal</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value="छ" onClick={handleMensurationconsult} />
                                            <span className="radio" />
                                            <span className="label">Expert Consult</span>
                                        </label>
                                    </div>


                                </div>
                                : ''
                            }
                            {Isfamilyinformation ?
                                <div className="legend">

                                    <label htmlFor="ward wise data" className="lvl">SARLAHI HOUSEHOLD INFO</label><br />
                                    <div className="bulgy-radios" role="radiogroup" aria-labelledby="bulgy-radios-label">

                                        <label>
                                            <input type="radio" name="options" value={Number(!0)} defaultChecked onClick={handleTotaldisabled} />
                                            <span className="radio" />
                                            <span className="label">Total Disabled</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value={Number(!0)} onClick={handleTotalinfantlessthan5} />
                                            <span className="radio" />
                                            <span className="label">Infant Below 5</span>
                                        </label>


                                        <label>
                                            <input type="radio" name="options" value={Number(!0)} onClick={handleChronicillness} />
                                            <span className="radio" />
                                            <span className="label">Chronic Illness</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value={Number(!0)} onClick={handleBreastfeeding} />
                                            <span className="radio" />
                                            <span className="label">Breast Feeding</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="options" value={Number(!0)} onClick={handlePregnant} />
                                            <span className="radio" />
                                            <span className="label">Pregnant Women</span>
                                        </label>

                                    </div>
                                </div>
                                : ''
                            }
                            {Iscovidinformation ?
                                <div className="legend">
                                    <label htmlFor="ward wise data" className="lvl">SARLAHI OVERALL COVID DATA</label><br />
                                    <div className="row">
                                        <div className="column" >
                                            <span > Total Cases</span><br />
                                            <span >Death</span><br />
                                        </div>
                                        <div className="column">
                                            <span className="covid-data-count" >{Covid_sarlahi_cases}</span><br />
                                            <span className="covid-data-count">{Covid_sarlahi_death}</span><br />
                                        </div>
                                    </div>
                                    <div className="head">
                                        <label htmlFor="ward wise data" className="lvl" >SARLAHI COVID19 INFO</label><br />
                                        <div className="bulgy-radios" role="radiogroup" aria-labelledby="bulgy-radios-label">
                                            <label>
                                                <input type="radio" name="options" value="छ" defaultChecked onClick={handleBreatheproblem} />
                                                <span className="radio" />
                                                <span className="label">Breathe Problem</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="options" value="छ" onClick={handleDrycough} />
                                                <span className="radio" />
                                                <span className="label">Dry Cough</span>
                                            </label>

                                            <label>
                                                <input type="radio" name="options" value="छ" onClick={handleTiredness} />
                                                <span className="radio" />
                                                <span className="label">Tiredness</span>
                                            </label>

                                            <label>
                                                <input type="radio" name="options" value="सामान्य (९६-९८.६ )" onClick={handleNormaltemperature} />
                                                <span className="radio" />
                                                <span className="label">Normal Temperature</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="options" value="ज्वरो (९८.६ -१०२ )" onClick={handleFever} />
                                                <span className="radio" />
                                                <span className="label">Fever</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="options" value="छ" onClick={handleReturnee} />
                                                <span className="radio" />
                                                <span className="label">Returnee</span>
                                            </label>


                                            <label>
                                                <input type="radio" name="options" value="थियो" onClick={handleQuarentined} />
                                                <span className="radio" />
                                                <span className="label">Quarentined</span>
                                            </label>


                                        </div>
                                    </div>

                                </div>
                                : ''
                            }
                            {Iswash ?
                                isLegend_wash_available ?
                                    Legend_wash_available.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />

                                        </Marker>)) :
                                    Sarlahi_wash_details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />
                                        </Marker>
                                    ))
                                : ''
                            }

                            {Issanitarypad ?
                                isLegend_sanitary_available ?
                                    Legend_sanitary_available.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />

                                        </Marker>)) :
                                    Sarlahi_Sanitary_Details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />
                                        </Marker>
                                    ))
                                : ''
                            }
                            {Istoiletavailable ?
                                isLegend_toilet_available ?
                                    Legend_toilet_available.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />
                                        </Marker>)) :
                                    Sarlahi_Toilet_Details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />

                                        </Marker>
                                    ))
                                : ''
                            }

                            {Isfamilyinformation ?
                                isLegend_family_details ?
                                    Legend_family_details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />

                                        </Marker>)) :
                                    Sarlahi_family_Details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />
                                        </Marker>
                                    ))
                                : ''
                            }
                            {Iscovidinformation ?
                                isLegend_covid_available ?
                                    Legend_covid_details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />

                                        </Marker>))


                                    :
                                    Sarlahi_COVID_Details.map((item, id) => (
                                        <Marker key={id}
                                            latitude={item.geometry.coordinates[1]}
                                            longitude={item.geometry.coordinates[0]}
                                        >
                                            <span className="dot" />
                                        </Marker>
                                    ))


                                : ''
                            }


                        </ReactMapGl>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Sarlahi;